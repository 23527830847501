import axios from "axios"
import * as React from "react"
import {
  GoogleMap,
  InfoWindow,
  Marker as GoogleMarker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps"
import { config } from "../config"
import pin from "../images/map/pin.png"
import pin_restrictive from "../images/map/pin_restrictive.png"
import selected_pin from "../images/map/selected_pin.png"
import selected_pin_restrictive from "../images/map/selected_pin_restrictive.png"
import "./Map.scss"

interface Place {
  boxId: number
  name: string
  latitude: number
  longitude: number
  address: string
  city: string
  zipCode: string
  openingHours: string
  BoxTypes: {
    isRestrictive: boolean
  }[]
}

const defaultProps = {
  center: {
    lat: 48.8259605,
    lng: 2.3513501,
  },
  zoom: 11,
}

const selectImage = (isRestrictive: boolean, isSelected: boolean) => {
  return isRestrictive
    ? isSelected
      ? selected_pin_restrictive
      : pin_restrictive
    : isSelected
    ? selected_pin
    : pin
}

const Map: React.FunctionComponent = () => {
  const [places, setPlaces] = React.useState<Place[]>([])
  const [selectedCenter, setSelectedCenter] = React.useState<Place>()

  React.useEffect(() => {
    async function getData() {
      try {
        let response = await axios.get(
          `${config.greengoUrl}/box?timestamp=${Date.now()}`
        )
        setPlaces(response.data)
      } catch (e) {
        setPlaces([])
      }
    }

    getData()
  }, [])

  return (
    <GoogleMap
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      {places.map(place => {
        return (
          <GoogleMarker
            labelClass="greengo-map-marker"
            key={place.boxId}
            position={{ lat: place.latitude, lng: place.longitude }}
            icon={{
              url: selectImage(
                place.BoxTypes[0].isRestrictive,
                selectedCenter === place
              ),
              // This is the real resolution of the image. Weirdly, maps will crop the image instead of resizing it.
              size: new google.maps.Size(48, 62),
              // The origin for this image is (24, 0).
              origin: new google.maps.Point(0, 0),
              // The anchor for this image is center bottom.
              anchor: new google.maps.Point(24, 62),
            }}
            onClick={() => {
              setSelectedCenter(place)
            }}
          >
            {selectedCenter === place && (
              <InfoWindow
                onCloseClick={() => {
                  setSelectedCenter(undefined)
                }}
                position={{
                  lat: selectedCenter!.latitude,
                  lng: selectedCenter!.longitude,
                }}
              >
                <div className="info">
                  <h2 className="info__name">{place.name}</h2>
                  <div className="info__separator"></div>
                  <p className="info__address">
                    {place.address}
                    <br />
                    {place.zipCode} {place.city}
                  </p>
                  {place.BoxTypes[0].isRestrictive && (
                    <p className="info__restrictive">
                      Réservé aux collaborateurs du site
                    </p>
                  )}
                  <a
                    className="info__go-location"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      place.address + " " + place.city
                    )}
                      `}
                  >
                    Y aller
                  </a>
                </div>
              </InfoWindow>
            )}
          </GoogleMarker>
        )
      })}
    </GoogleMap>
  )
}

const MountedMap = withScriptjs(withGoogleMap(Map))

const FinalMap: React.FunctionComponent = () => {
  return (
    <div className="internal-map">
      <MountedMap
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAjNqbuskcxVB97_DUAtIoPHPwo4qDT3VA&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      ></MountedMap>
    </div>
  )
}

export default FinalMap
